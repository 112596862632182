<template>
  <v-text-field
    @input="$emit('search', $event)"
    outlined
    rounded
    clearable
    hide-details
    append-icon="mdi-magnify"
    dense
    label="Search"
    class="mx-2"
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchQuery",
};
</script>
