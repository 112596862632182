<template>
  <v-select
    :items="filterOptions"
    clearable
    @change="$emit('change', $event)"
    :label="filterLabel"
  ></v-select>
</template>

<script>
export default {
  name: "FilterSelect",
  props: {
    filterOptions: Array,
    filterLabel: String,
  },
};
</script>
