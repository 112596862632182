<template>
  <v-card class="elevation-2">
    <v-dialog width="400" v-model="assessmentStartDialog">
      <v-card v-if="selectedAssessment">
        <v-card-title :class="`secondary-${subject.color}-bg`">
          Start Assessment
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4" v-if="selectedAssessment.accessCode !== null">
          <v-text-field
            type="text"
            @input="validateInput($event)"
            :value="selectedAssessment.inputCode"
            :rules="accessCodeRules"
            ref="accessCodeField"
            dense
            label="Access Code : "
            outlined
          />
        </v-card-text>
        <v-card-text class="pa-4" v-else>
          Proceed taking assessment - {{ selectedAssessment.title }} ?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="green accent-4"
            :disabled="selectedAssessment.accessCode !== null && !selectedAssessment.accessible"
            @click="proceedAssessment(selectedAssessment)"
          >
            Proceed
          </v-btn>
          <v-btn @click="assessmentStartDialog = false" color="red" dark
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6" class="d-flex justify-center justify-sm-start">
          <h3>Assessments</h3>
        </v-col>
        <v-col cols="12" class="d-flex d-sm-none">
          <FilterSelect
            filterLabel="Quarter"
            :filterOptions="quarterOptions"
            @change="filterAssessmentsByQuarter = $event"
          />
        </v-col>
        <v-row class="flex-md-row-reverse">
          <v-col
            class="d-flex justify-md-end"
            cols="12"
            :md="quarterOptions.length > 2 ? '12' : '6'"
          >
            <SearchQuery @search="searchInputQuery = $event" />
          </v-col>
          <v-col
            class="d-none d-sm-flex align-end pa-2"
            cols="12"
            :md="quarterOptions.length > 2 ? '12' : '6'"
          >
            <v-btn-toggle
              v-model="filterAssessmentsByQuarter"
              :color="`${subject.color} darken-3`"
              mandatory
            >
              <v-btn
                v-for="quarter in quarterOptions"
                :key="quarter"
                :value="quarter"
                large
                text
              >
                Quarter {{ quarter }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!filteredAssessments.length > 0" class="py-4">
      <v-banner two-line>
        <v-icon block slot="icon" color="info" size="36">
          mdi-information-outline
        </v-icon>
        No Assessments Found.
      </v-banner>
    </v-card-text>
    <v-card-text v-else class="py-6">
      <v-row>
        <v-col
          v-for="(assessment, index) in filteredAssessments"
          :key="index"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
        >
          <v-card tile elevation="2">
            <v-sheet
              tile
              :class="`secondary-${subject.color}-bg py-2 folder-style-no-skew`"
            ></v-sheet>
            <v-sheet
              tile
              :class="`secondary-${subject.color}-bg py-2 ml-5 folder-style-skew`"
            ></v-sheet>
            <v-card-text class="pa-2">
              <v-row class="pa-3 d-flex justify-space-between">
                <v-tooltip :open-on-hover="false" left>
                  <template v-slot:activator="{ on, attrs }">
                    <h4 class="text-truncate" v-bind="attrs" v-on="on">
                      {{ assessment.title }}
                    </h4>
                  </template>
                  <span> {{ assessment.title }}</span>
                </v-tooltip>
                <div>
                  <v-chip
                    class="mr-2"
                    v-show="assessment.performanceTask"
                    label
                    outlined
                    x-small
                  >
                    Performance Task
                  </v-chip>
                  <v-chip
                    v-show="assessment.writtenWork"
                    label
                    outlined
                    x-small
                  >
                    Written Works
                  </v-chip>
                </div>
              </v-row>
              <sup
                >{{ assessment.type.toUpperCase() }} | TOTAL SCORE :
                {{ assessment.totalScore }}</sup
              >
              <p>
                Duration : {{ assessment.duration }} minute{{
                  assessment.duration > 1 ? "s" : ""
                }}
              </p>
              <p>
                Attempts :
                {{ assessment.studentAssessments.length }}
                out of
                {{ assessment.attempts }}
                {{
                  assessment.attempts > 1
                    ? isRecordHighest(assessment.recordHighest)
                    : ""
                }}
                <v-tooltip
                  v-if="assessment.attempts > 1"
                  :open-on-hover="false"
                  :open-on-focus="false"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="20"
                      color="blue accent-4"
                      >mdi-help-circle-outline</v-icon
                    >
                  </template>
                  <p>
                    {{
                      assessment.recordHighest
                        ? "Accepts record with the highest score in all attempts"
                        : "Accepts record of the last attempt"
                    }}
                  </p>
                </v-tooltip>
              </p>
              <p>
                Score :
                {{
                  getScore(
                    assessment.studentAssessments,
                    assessment.recordHighest
                  )
                }}
              </p>
              <div style="height: 40px">
                <p v-if="assessment.from && assessment.to">
                  Schedule : {{ sanitizeDate(assessment.from) }} to
                  {{ sanitizeDate(assessment.to) }}
                </p>
              </div>
              <div class="mt-10 d-flex justify-end">
                <div v-if="assessment.offline">
                  <v-btn
                    v-if="downloading === assessment.id"
                    :loading="true"
                    small
                    color="primary"
                  >Downloading...
                  </v-btn>
                  <v-btn
                    v-else-if="assessment.downloaded"
                    disabled
                    small
                  >Downloaded
                    <v-icon
                      right
                      dark
                    >
                      mdi-check-underline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    :disabled="disableDownload(assessment)"
                    @click="downloadAssessment(assessment)"
                    small
                    color="primary"
                  >Download
                    <v-icon
                      right
                      dark
                    >
                      mdi-download
                    </v-icon>
                  </v-btn>
                </div>                
                <v-spacer></v-spacer>
                <v-btn
                  v-if="checkAttempt(assessment)"
                  small
                  rounded
                  right
                  class="main-green-bg white--text"
                  :to="{
                    name: 'TakeAssessment',
                    query: { id: assessment.id },
                  }"
                  >RESUME
                  <v-icon class="ml-1" small>
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
                <div v-else-if="assessment.pendingSync">
                  <v-btn
                    v-if="syncing === assessment.id"
                    :loading="true"
                    small
                    rounded
                    right
                    color="primary"
                  >Loading...
                  </v-btn>
                  <v-btn
                    v-else
                    :disabled="syncing !== assessment.id && syncing !== 0"
                    @click="syncAssessment(assessment.id)"
                    small
                    rounded
                    right
                    class="primary"
                  >TURN IN
                    <v-icon small>
                      mdi-sync-circle
                    </v-icon>
                  </v-btn>
                </div>
                <v-btn
                  v-else
                  @click="
                    selectAssessment(
                      assessment,
                      assessment.studentAssessments.length
                    )
                  "
                  :disabled="
                    !assessment.status ||
                    !allowStart(assessment.from, assessment.to) ||
                    consumedAllAttempts(
                      assessment.attempts,
                      assessment.studentAssessments.length
                    )
                  "
                  small
                  rounded
                  right
                  class="main-green-bg white--text"
                  >START
                  <v-icon class="ml-1" small>
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import router from "@/router";
import localforage from "../plugins/localforage";
//import io from "socket.io-client";
export default {
  name: "AssessmentAssigned",
  components: {
    SearchQuery,
    FilterSelect,
  },
  data() {
    return {
      //socket: {},
      accessCodeRules: [
        (value) => this.selectedAssessment.accessCode === value || 'Invalid Access Code'
      ],
      downloading: 0,
      syncing: 0,
      unlockDialog: false,
      assessmentStartDialog: false,
      selectedAssessment: null,
      filterAssessmentsByQuarter: "",
      searchInputQuery: "",
    };
  },
  computed: {
    ...mapState({
      assessments: (state) => state.assessmentModule.assessments,
      studentAssessments: (state) => state.assessmentModule.studentAssessments,
      assessmentsLoading: (state) => state.assessmentModule.assessmentsLoading,
      subject: (state) => state.subjectsModule.subject,
      quarterOptions: (state) => state.subjectsModule.quarterOptions,
    }),
    currentUser() {
      return this.$store.getters['authModule/getUser'];
    },
    filteredAssessments() {
      let assessmentsByQuarter = [],
        quarter = this.filterAssessmentsByQuarter,
        search = this.searchInputQuery;

      if (this.assessments) {
        if (this.assessmentsAreYearLong) {
          assessmentsByQuarter = this.assessments;
        } else {
          assessmentsByQuarter = this.assessments.filter(
            (assessment) => assessment.quarter == quarter
          );
        }

        if (search) {
          assessmentsByQuarter = assessmentsByQuarter.filter((assessment) =>
            assessment.title.toLowerCase().includes(search.toLowerCase())
          );
        }
      }

      return assessmentsByQuarter;
    },
  },
  // created() {
  //   this.socket = io(process.env.VUE_APP_ASL_BASE_URL);
  // },
  methods: {
    moment: moment,
    async syncAssessment(assessmentId) {
      this.syncing = assessmentId;
      let payload = {
        assessmentId,
        subjectId : this.subject.id
      }
      await this.$store.dispatch("assessmentModule/syncAssessment", payload);
      this.syncing = 0;
    },
    async validateInput(accessCode) {
      const isValid = this.selectedAssessment.accessCode === accessCode;
      if (isValid) {
        const assessmentId = this.selectedAssessment.id;
        this.selectedAssessment.accessible = true;
        await localforage.setItem(`access_code_${assessmentId}`, accessCode);
      } else {
        this.selectedAssessment.accessible = false;
      }

      this.$refs.accessCodeField.validate();
    },
    async downloadAssessment(assessment) {
      this.downloading = assessment.id;
      await this.$store.dispatch("assessmentModule/downloadOfflineAssessment", assessment);
      this.downloading = 0;
    },
    disableDownload(assessment) {
      let disableDownload = false;
      if ((this.downloading !== assessment.id && this.downloading !== 0) || this.consumedAllAttempts(assessment.attempts, assessment.studentAssessments.length)) {
        disableDownload = true
      }

      return disableDownload;
    },
    checkAttempt(assessment) {
      let resume = false;
      if (assessment.studentAssessments.length > 0) {
        resume = assessment.studentAssessments.at(-1)?.dateEnd ? false : true;
      }
      return resume;
    },
    getScore(studentAssessments, recordHighest) {
      let score = "N/A",
        studentScores = [];

      studentAssessments?.map((assessment) => {
        if (assessment.score !== null) {
          studentScores.push(assessment.score);
        }
      });

      if (studentScores.length > 0) {
        if (recordHighest) {
          score = Math.max(...studentScores);
        } else {
          score = studentScores.at(-1);
        }
      }

      return score;
    },
    async proceedAssessment(assessment) {
      const result = await this.$store.dispatch(
        "assessmentModule/takeAssessment",
        assessment
      );

      if (result) {
        return router.push({
          name: "TakeAssessment",
          query: { id: assessment.id },
        });
      } else {
        this.selectedAssessment = null;
        this.assessmentStartDialog = false;
      }
    },
    sanitizeDate(date) {
      let sanitizedDate = moment(date).format("YYYY-MM-DD hh:mm A");

      return sanitizedDate;
    },
    consumedAllAttempts(allowedAttempts, attempts) {
      let consumedAll = true;
      if (allowedAttempts > attempts) {
        consumedAll = false;
      }

      return consumedAll;
    },
    allowStart(dateFrom, dateTo) {
      let allowStart = true;
      if (dateFrom && dateTo) {
        allowStart =
          moment().isAfter(moment(dateFrom)) &&
          moment().isBefore(moment(dateTo));
      }

      return allowStart;
    },
    async selectAssessment(assessment, attempt) {
      this.selectedAssessment = {
        ...assessment,
        studentId: this.currentUser.id,
        attempt: attempt ? attempt + 1 : 1,
      };
      this.assessmentStartDialog = true;
    },
    isRecordHighest(isTrue) {
      if (isTrue) {
        return "(accepts highest score)";
      } else {
        return "(accepts last attempt)";
      }
    },
  },
  async mounted() {
    const subjectId = this.$store.state.subjectsModule.subject.id;

    await this.$store.dispatch("assessmentModule/getAssessments", subjectId);
    // this.socket.on(`student:${this.currentUser.id}`, async () => {
    //   this.assessmentStartDialog = false;
    //   await this.$store.dispatch("assessmentModule/getAssessments", subjectId);
    // });
  },
};
</script>
<style scoped>
.folder-style-no-skew {
  width: 20%;
  position: absolute;
  margin-top: -16px;
}
.folder-style-skew {
  width: 35%;
  position: absolute;
  margin-top: -16px;
  transform: skewX(50deg);
  -webkit-transform: skewX(50deg);
  -moz-transform: skewX(50deg);
  -ms-transform: skewX(50deg);
}

p {
  margin: 0;
}
</style>
